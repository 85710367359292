<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Visualizar Venda</h4>
          <div class="page-title-right">
            <b-button
              variant="warning"
              @click.prevent="
                $router.push({ name: 'venda', params: { id: sale.id } })
              "
            >
              <i class="fas fa-edit mr-0 mr-md-2"></i>
              <span class="d-none d-md-inline-block">Editar Venda</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="info"
              @click.prevent="$router.push({ name: 'vendas' })"
            >
              <i
                class="bx bx-list-ol font-size-16 align-middle mr-0 mr-md-2"
              ></i>
              <span class="d-none d-md-inline-block">Listar Vendas</span>
            </b-button>
            <!--<b-button
                class="ml-2"
                variant="success"
                @click.prevent="$router.push({ name: 'produto' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-0 mr-md-2"></i>
              <span class="d-none d-md-inline-block">Adicionar</span>
            </b-button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="sale == null">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Item não encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row text-left">
        <div class="col-md-12 col-lg-12 col-xl-8">
          <div class="card">
            <div class="card-body">
              <div class="invoice-title">
                <h4 class="float-right font-size-16">
                  Venda #{{ sale.id }}
                  <a
                    href="javascript:window.print()"
                    class="btn btn-success ml-2 d-print-none"
                  >
                    <i class="fa fa-print"></i>
                  </a>
                </h4>
                <div class="mb-4">
                  <span v-for="(img, index) in store.image" :key="index">
                    <img
                      :src="img.image"
                      v-if="img.logo_dark == 1"
                      alt="logo"
                      height="50"
                    />
                  </span>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-6">
                  <address>
                    <strong>Cliente:</strong>
                    <br />
                    {{ sale.customer_name }}
                    <br />
                    {{ sale.customer_email }}
                    <br />
                    {{ sale.customer_phone }}
                  </address>
                </div>
                <div class="col-6 text-right">
                  <address>
                    <strong>Endereço de Entrega:</strong>
                    <br />
                    {{ sale.customer_name }}
                    <br />
                    {{ sale.delivery_street }}, {{ sale.delivery_number }} -
                    {{ sale.delivery_complement }}
                    <br />
                    {{ sale.delivery_city }}/{{ sale.delivery_state }} -
                    {{ sale.delivery_cep }}
                  </address>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mt-3">
                  <address>
                    <strong>Método de Pagamento:</strong>
                    <br />
                    {{ sale.payment_method == '' ? '-' : sale.payment_method }}
                    <br />
                    {{ sale.payment_date == '' ? '-' : sale.payment_date }}
                  </address>
                </div>
                <div class="col-6 mt-3 text-right">
                  <address>
                    <strong>Data da venda:</strong>
                    <br />
                    {{ sale.created_at | moment('DD/MM/YYYY HH:mm') }}
                    <br />
                    <br />
                  </address>
                </div>
              </div>
              <div class="p-2 mt-3">
                <h3 class="font-size-16">Detalhes</h3>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 70px;">N°.</th>
                      <th>Imagem</th>
                      <th>Item</th>
                      <th class="text-center">Preço</th>
                      <th class="text-center">Quantidade</th>
                      <th class="text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, index) in sale.product"
                      :key="product.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td v-if="product.image != null && product.image != ''">
                        <span
                          v-for="(img, i) in product.image"
                          :key="i + product.id"
                        >
                          <img
                            v-if="img.capa == 1"
                            :src="img.image300"
                            class="rounded"
                            :alt="product.name"
                            height="50"
                          />
                        </span>
                      </td>
                       <td v-else>
                          <img
                            src="@/assets/images/notpro.jpg"
                            class="rounded"
                            :alt="product.name"
                            height="50"
                          />
                      </td>
                      <td>
                        {{ product.name }}
                        <p v-if="product.options && product.options != null && product.options != ''">
                        <span class="w-100 d-block mt-3" v-for="(product, index) in JSON.parse(product.options)" :key="index">
                          {{index}}: <span class="border p-1 ml-1" v-for="(p, i) in product" :key="i">{{p.name}}</span>
                        </span>
                      </p>
                      </td>
                      <td class="text-center">
                        {{ product.price | currency('R$ ') }}
                      </td>
                      <td class="text-center">{{ product.amount }}</td>
                      <td class="text-center">
                        {{ (product.price * product.amount) | currency('R$ ') }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-right">Sub Total</td>
                      <td class="text-right">
                        {{ sale.price_subtotal | currency('R$ ') }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="border-0 text-right">
                        <strong>Frete</strong>
                      </td>
                      <td class="border-0 text-right">
                        {{
                          sale.shipping_type == 'Grátis'
                            ? 'Grátis'
                            : sale.shipping_price | currency('R$ ')
                        }}
                      </td>
                    </tr>
                    <tr v-if="sale.juros > 0">
                      <td colspan="5" class="border-0 text-right">
                        <strong>Juros</strong>
                      </td>
                      <td class="border-0 text-right">
                        {{
                          sale.juros | currency('R$ ')
                        }}
                      </td>
                    </tr>
                    <!--<tr>
                      <td colspan="5" class="border-0 text-right">
                        <strong>Cupom</strong>
                      </td>
                      <td class="border-0 text-right text-danger">-R$ 13,00</td>
                    </tr>-->
                    <tr>
                      <td colspan="5" class="border-0 text-right">
                        <strong>Total</strong>
                      </td>
                      <td class="border-0 text-right">
                        <h4 class="m-0">
                          {{ sale.price_total | currency('R$ ') }}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-4 d-print-none">
          <div class="card">
            <div class="card-body">
              <div class="invoice-title mb-4 pb-2">
                <h4 class="float-left font-size-16">
                  Status de Pagamento
                </h4>
              </div>
              <hr />
              <p>
                Status:
                <b-badge variant="info" class="ml-2">
                  {{ options_pagamento_status[sale.payment_status].text }}
                </b-badge>
              </p>
              <label class="mt-2">Atualizar Status</label>
              <multiselect
                v-model="status_pagamento"
                :options="options_pagamento_status"
                class="helo"
                label="text"
                placeholder="Status de pagamento"
                select-label="Selecionar"
                deselect-label="Remover"
                :allow-empty="false"
              ></multiselect>
              <label class="mt-3">Método de Pagamento</label>
              <b-form-input
                v-model="sale.payment_method"
                placeholder="Método de pagamento"
              ></b-form-input>
              <b-button
                class="mt-3 float-right"
                variant="success"
                @click.prevent="submitStatus(1)"
              >
                Atualizar
              </b-button>
            </div>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <div class="invoice-title mb-4 pb-2">
                <h4 class="float-left font-size-16">
                  Status de Frete
                </h4>
              </div>
              <hr />
              <p>
                Status:
                <b-badge variant="info" class="ml-2">
                  {{ options_frete_status[sale.shipping_status].text }}
                </b-badge>
              </p>
              <label class="mt-2">Atualizar Status</label>
              <multiselect
                v-model="status_frete"
                :options="options_frete_status"
                class="helo"
                label="text"
                placeholder="Status de frete"
                select-label="Selecionar"
                deselect-label="Remover"
                :allow-empty="false"
              ></multiselect>
              <label class="mt-3">Código de Rastreio</label>
              <b-form-input
                v-model="sale.shipping_code"
                placeholder="Código de rastreio"
              ></b-form-input>
              <b-button
                class="mt-3 float-right"
                variant="success"
                @click.prevent="submitStatus(2)"
              >
                Atualizar
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-print-none" v-if="sale.historic != null">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="invoice-title mb-4 pb-2">
                <h4 class="float-left font-size-16">
                  Histórico
                </h4>
              </div>
              <hr />
              <div class="row">
                <div class="col-12">
                    <b-table
                      :items="sale.historic"
                      :fields="fields"
                      responsive
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      caption-top
                      hover
                      head-variant="light"
                      class="text-left"
                    >
                      <template #cell(status)="data">
                         {{ data.status == 0 ? 'Pendente' : 'Concluido' }}
                      </template>
                      <template #cell(created_at)="data">
                        {{ data.item.created_at | moment('DD/MM/YYYY') }}
                      </template>
                    </b-table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      status_frete: null,
      status_pagamento: null,
      options_frete_status: [
        { value: 0, text: 'Pendente' },
        { value: 1, text: 'Preparando para envio' },
        { value: 2, text: 'Enviado' },
        { value: 3, text: 'Entregue' },
      ],
      options_pagamento_status: [
        { value: 0, text: 'Pendente' },
        { value: 1, text: 'Processando' },
        { value: 2, text: 'Cancelado' },
        { value: 3, text: 'Pago' },
      ],
      sale: null,
      loading: true,
      error: false,
      sortBy: 'created_at',
      sortDesc: true,
      fields: [
        {
          key: 'created_at',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'comment',
          label: 'Comentário',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'additional',
          label: 'Adicional',
          sortable: true,
        },
      ],
      store: null,
    }
  },
  mounted() {
    if (this.$route.params.id <= 0) {
      this.loading = false
      this.error = true
    }
    this.findSale()
    this.findStore()
  },
  methods: {
    ...mapActions('sale', ['ActionSaleFind', 'ActionSaleStatusUpdate']),
    ...mapActions('appearance', ['ActionAppearanceFind']),
    async findStore() {
      try {
        await this.ActionAppearanceFind().then((res) => {
          this.store = res.data.data
        })
      } catch (e) {
        this.store = null
      }
    },
    async findSale() {
      try {
        await this.ActionSaleFind(this.$route.params.id).then((res) => {
          this.sale = res.data.data
          this.status_pagamento = this.options_pagamento_status[
            this.sale.payment_status
          ]
          this.status_frete = this.options_frete_status[
            this.sale.shipping_status
          ]
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.sale = null
      }
    },
    async submitStatus(tipo) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        this.sale.shipping_status = this.status_frete.value
        this.sale.payment_status = this.status_pagamento.value
        this.sale.tipo = tipo
        var d = this.sale
        d.historic = null
        var dados = {
          data: d,
          id: this.sale.id,
        }
        await this.ActionSaleStatusUpdate(dados).then((res) => {
          this.sale = res.data.data
          this.$swal({
            title: 'Sucesso',
            text: 'Ação realizada com sucesso!',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
          })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
